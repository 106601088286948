import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUserData } from "../../actions/actions";
import CustomHeader from "../../components/CustomHeader/CustomHeader";
import CustomSelectCard from "../../components/CustomSelectCard/CustomSelectCard";
import { APP_CONFIG } from "../../constants/config";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

    /* flexGrow: 1, */
    /* width: '100vw',
		height: '100vh', */
  },
  main: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "10px",
  },
  header: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  dadosBox: {
    display: "flex",
    flexDirection: "row",
    /* alignItems: 'center', */
    /* justifyContent: 'center', */
    marginTop: "30px",
    marginLeft: "30px",
  },
  cardContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
  },
  contadorStyle: {
    display: "flex",
    fontSize: "30px",
    fontFamily: "Montserrat-SemiBold",
  },
}));
export default function FolhaDePagamento() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const token = useAuth();
  const userData = useSelector((state) => state.userData);

  useEffect(() => {
    dispatch(loadUserData(token));
  }, [token]);

  return (
    <Box className={classes.root}>
      <Box className={classes.main}>
        <CustomHeader
          pageTitle="Beneficiários"
          folhaDePagamento
          isSearchVisible={false}
        />

        <Box className={classes.dadosBox}>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                backgroundColor: APP_CONFIG.mainCollors.backgrounds,
                alignItems: "center",
                borderRadius: "17px",
                flexDirection: "column",
                width: "90%",

                /* alignItems: 'center', */
              }}
            >
              <Box
                style={{
                  width: "100%",
                  backgroundColor: APP_CONFIG.mainCollors.backgrounds,
                  height: "800px",
                  borderRadius: 27,
                  borderTopLeftRadius: 27,
                  borderTopRightRadius: 27,
                }}
              >
                <Box
                  display="flex"
                  style={{
                    marginTop: "10px",
                    marginBottom: "16px",
                    margin: 30,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat-ExtraBold",
                      fontSize: "16px",
                      color: APP_CONFIG.mainCollors.primary,
                      marginTop: "30px",
                      marginLeft: "40px",
                    }}
                  >
                    Selecione a ação que você deseja realizar
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: !matches ? "flex" : "column",
                  }}
                >
                  { (userData && userData.is_estabelecimento == false) ? (
                  <CustomSelectCard
                    title="Beneficiários"
                    icon="personAdd"
                    url="beneficiarios/acao/lista-beneficiarios"
                  />
                ) : null }
                { (userData && userData.is_estabelecimento == false) ? (
                  <CustomSelectCard
                    title="Cartões Privados"
                    icon="card"
                    url="beneficiarios/acao/lista-cartoes"
                  /> 
                ) : null } 
                { (userData && userData.is_estabelecimento == false) ? (
                  <CustomSelectCard
                    title="Cartões Pré"
                    icon="card"
                    url="beneficiarios/acao/lista-cartoes-pre"
                  /> 
                ) : null }
                  <CustomSelectCard
                    title="Transferências"
                    icon="transferencia"
                    url="beneficiarios/acao/transacoes"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
