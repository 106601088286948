import {
  Box,
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/styles";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import { Pagination } from "@mui/material";
import { DropzoneAreaBase } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { loadUserData, setHeaderLike, loadPermissaoGerenciar } from "../../../actions/actions";
import useAuth from "../../../hooks/useAuth";
import {
  deleteCartao,
  getCartoes,
  postAddCartao,
  postAddLoteCartoes,
  putUpdateCartao,
} from "../../../services/beneficiarios";

import ReactInputMask from "react-input-mask";
import CustomHeader from "../../../components/CustomHeader/CustomHeader";
import CustomTable from "../../../components/CustomTable/CustomTable";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import { APP_CONFIG } from "../../../constants/config";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "10px",
  },
  header: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  dadosBox: {
    display: "flex",
    flexDirection: "row",
    marginTop: "30px",
    marginLeft: "30px",
  },
  cardContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
  },
  contadorStyle: {
    display: "flex",
    fontSize: "30px",
    fontFamily: "Montserrat-SemiBold",
  },
  paper: {
    backgroundColor: APP_CONFIG.mainCollors.backgrounds,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    boxShadow: "none",
    borderRadius: "0px",
    alignSelf: "center",
  },
  modal: {
    outline: " none",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    position: "absolute",

    top: "10%",
    left: "35%",
    width: "30%",
    height: "80%",
    backgroundColor: "white",
    border: "0px solid #000",
    boxShadow: 24,
  },

  closeModalButton: {
    alignSelf: "end",
    padding: "5px",
    "&:hover": {
      backgroundColor: APP_CONFIG.mainCollors.primaryVariant,
      cursor: "pointer",
    },
  },
  dropzoneAreaBaseClasses: {
    width: "70%",
    height: "250px",
    backgroundColor: APP_CONFIG.mainCollors.backgrounds,
  },
  dropzoneContainer: {
    margin: "6px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "12px",
    minHeight: "422px",
    fontSize: "12px",
  },
  textoDropzone: {
    fontSize: "1.2rem",
    color: APP_CONFIG.mainCollors.primary,
  },
}));

const columns = [
  { headerText: "ID", key: "external_id" },
  {
    headerText: "FINAL",
    key: "external_msk",
    CustomValue: (valor) => {
      return (
        <>
          <Typography
            style={{
              fontFamily: "Montserrat-Regular",
              fontSize: "15px",
              color: APP_CONFIG.mainCollors.primary,
            }}
          >
            {valor.replace(/\*/g, "")}
          </Typography>
        </>
      );
    },
  },
  {
    headerText: "SALDO",
    key: "user.concorrencia_saldo.valor",
    CustomValue: (valor) => {
      return (
        <>
          <Typography
            style={{
              fontFamily: "Montserrat-Regular",
              fontSize: "15px",
              color: APP_CONFIG.mainCollors.primary,
            }}
          >
            R${" "}
            {parseFloat(valor).toLocaleString("pt-br", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </>
      );
    },
  },
  { headerText: "STATUS", key: "status" },
  { headerText: "NOME", key: "user.nome" },
  { headerText: "CPF", key: "user.documento" },
  { headerText: "", key: "menu" },
];

const CadastrarLoteModal = ({
  show = false,
  setShow = () => false,
  getData = () => null,
}) => {
  const token = useAuth();
  const classes = useStyles();
  const [loteArquivo, setLoteArquivo] = useState("");
  const [loading, setLoading] = useState(false);
  var cardImage = loteArquivo[0];

  const handleClose = () => {
    setShow(false);
    setLoteArquivo("");
  };

  const handleCriarLote = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await postAddLoteCartoes(token, loteArquivo[0].file);
      getData();
      handleClose();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  const onDropArquivo = async (arquivo) => {
    setLoteArquivo(
      arquivo.map((item, index) => {
        return item;
      })
    );
  };

  const handleExcluirArquivo = async (item) => {
    setLoteArquivo("");
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <LoadingScreen isLoading={loading} />
      <DialogTitle id="form-dialog-title">
        Cadastrar em lote por arquivo
      </DialogTitle>
      <form onSubmit={handleCriarLote}>
        <DialogContent>
          <DialogContentText>
            Siga as instruções e use o arquivo modelo:
          </DialogContentText>
          <Link
            target="_blank"
            download
            to="/arquivos/Instruções - Cadastro de Funcionario.xlsx"
          >
            <Button>
              <DownloadIcon />
              Instruções
            </Button>
          </Link>
          <Link
            target="_blank"
            download
            to="/arquivos/Arquivo Modelo - Cadastro de Cartao Beneficiario.csv"
          >
            <Button>
              <DownloadIcon />
              Arquivo modelo
            </Button>
          </Link>
          <DialogContentText>Insira o arquivo abaixo:</DialogContentText>
          <Box className={classes.dropzoneContainer}>
            <DropzoneAreaBase
              dropzoneParagraphClass={classes.textoDropzone}
              maxFileSize={3145728}
              onDropRejected={() => {
                toast.error("Tamanho máximo: 3mb ");
                toast.error("Arquivos suportados: .csv ");
              }}
              acceptedFiles={["text/csv", "text/plain"]}
              dropzoneClass={classes.dropzoneAreaBaseClasses}
              onAdd={onDropArquivo}
              filesLimit={1}
              dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
              showPreviews={false}
              showPreviewsInDropzone={false}
            />
            <Box width="300px" style={{ marginTop: "10px" }}>
              <Grid container>
                {loteArquivo ? (
                  <Grid item xs={6}>
                    <Card className={classes.card}>
                      <CardActionArea>
                        <Box position="absolute">
                          <IconButton
                            onClick={() => handleExcluirArquivo(loteArquivo)}
                            size="small"
                            style={{
                              color: "white",
                              backgroundColor: "red",
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100px",
                          }}
                          onClick={() => window.open(cardImage.data)}
                        >
                          <ArticleIcon
                            style={{
                              color: "black",
                              fontSize: "40px",
                            }}
                          />
                          <Typography style={{ fontSize: 12 }}>
                            {cardImage.file.name}
                          </Typography>
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button color="primary" type="submit">
            Enviar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CartaoModal = ({
  show = false,
  setShow = () => false,
  getData = () => null,
  data = {},
  update = false,
}) => {
  const token = useAuth();
  const [conta, setConta] = useState({
    documento: data?.user?.documento,
    cdCartao: data?.external_id,
    externoMsk: data?.external_msk,
    // vlSaldo: data?.user?.concorrencia_saldo?.valor?.replace(/\./g, ",") ?? 0.0,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState("");

  const handleClose = () => {
    setShow(false);

    if (!update) {
      setConta({
        documento: "",
        cdCartao: "",
        externoMsk: "",
        // vlSaldo: 0.0,
      });
    }
  };

  const handleCriarCartao = async (e) => {
    e.preventDefault();
    setErrors({});
    setLoading(true);
    try {
      if (update) {
        await putUpdateCartao(token, data?.id, conta);
      } else {
        await postAddCartao(token, conta);
      }
      getData();
      handleClose();
    } catch (err) {
      console.log(err);
      setErrors(err?.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <LoadingScreen isLoading={loading} />
      <DialogTitle id="form-dialog-title">
        {update ? "Editar" : "Cadastrar"} cartão
      </DialogTitle>
      <form onSubmit={handleCriarCartao}>
        <DialogContent style={{ overflow: "hidden" }}>
          <Grid container spacing={4}>
            {/* <Grid item xs={12}>
              <InputLabel
                style={{
                  color: "#15191E",
                  transform: "translate(14px) scale(0.8)",
                  fontFamily: "Montserrat-SemiBold",
                }}
              >
                Saldo
              </InputLabel>
              <CurrencyInput
                style={{
                  height: 40,
                  fontSize: 20,
                  border: "none",
                  color: "rgba(0, 0, 0, 0.87)",
                  backgroundColor: "transparent",
                  fontFamily: "Montserrat-Regular",
                }}
                prefix="R$"
                decimalSeparator=","
                thousandSeparator="."
                value={conta.vlSaldo}
                onChange={(e, value) => {
                  setConta((prev) => ({
                    ...prev,
                    vlSaldo: value,
                  }));
                }}
              />
              {errors?.vlSaldo ? (
                <FormHelperText
                  style={{
                    fontSize: 14,
                    textAlign: "center",
                    fontFamily: "Montserrat-ExtraBold",
                    color: "red",
                  }}
                >
                  {errors?.vlSaldo?.join(" ")}
                </FormHelperText>
              ) : null}
            </Grid> */}

            <Grid item xs={12}>
              <ReactInputMask
                mask={"999.999.999-99"}
                value={conta.documento}
                onChange={(e) =>
                  setConta((prev) => ({
                    ...prev,
                    documento: e.target.value,
                  }))
                }
              >
                {() => (
                  <TextField
                    label={"Documento"}
                    error={errors["documento"]}
                    helperText={
                      errors["documento"]
                        ? errors["documento"]?.join(" ")
                        : null
                    }
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required
                  />
                )}
              </ReactInputMask>
            </Grid>

            <Grid item xs={12}>
              <ReactInputMask
                mask={"999"}
                value={conta.cdCartao}
                onChange={(e) =>
                  setConta((prev) => ({
                    ...prev,
                    cdCartao: e.target.value,
                  }))
                }
              >
                {() => (
                  <TextField
                    label={"ID Cartão"}
                    error={errors["cdCartao"]}
                    helperText={
                      errors["cdCartao"] ? errors["cdCartao"]?.join(" ") : null
                    }
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required
                  />
                )}
              </ReactInputMask>
            </Grid>

            <Grid item xs={12}>
              <ReactInputMask
                mask={"9999"}
                value={conta.externoMsk}
                onChange={(e) =>
                  setConta((prev) => ({
                    ...prev,
                    externoMsk: e.target.value,
                  }))
                }
              >
                {() => (
                  <TextField
                    label={"Final Cartão"}
                    error={errors["externoMsk"]}
                    helperText={
                      errors["externoMsk"]
                        ? errors["externoMsk"]?.join(" ")
                        : null
                    }
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required
                  />
                )}
              </ReactInputMask>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button color="primary" type="submit">
            Enviar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const DeletarCartaoModal = ({
  show = false,
  setShow = () => false,
  getData = () => null,
  data = {},
}) => {
  const token = useAuth();
  const [loading, setLoading] = useState("");

  const handleClose = () => {
    setShow(false);
  };

  const handleDeletarCartao = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await deleteCartao(token, data?.id);
      getData();
    } catch (err) {
      console.log(err);
      toast.error(
        "Ocorreu um erro, não possivel deletar o cartão. Tente novamente."
      );
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <LoadingScreen isLoading={loading} />
      <DialogTitle id="form-dialog-title">Excluir cartão</DialogTitle>
      <form onSubmit={handleDeletarCartao}>
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText>
            Você gostaria de excluir o cartão:
          </DialogContentText>
          <DialogContentText>
            ID: {data?.external_id}
            <br />
            Final: {data?.external_msk?.replace(/\*/g, "")}
            <br />
            {data?.user?.nome}
            <br />
            {data?.user?.documento}
          </DialogContentText>
          <DialogContentText>Essa ação é irreversível.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button color="primary" type="submit">
            Excluir
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const MenuTable = ({ getData, row }) => {
  const [showEditarCartaoModal, setShowEditarCartaoModal] = useState(false);
  const [showDeletarCartaoModal, setShowDeletarCartaoModal] = useState(false);

  return (
    <Box>
      <Box style={{ display: "flex" }}>
        <Box onClick={() => setShowEditarCartaoModal(true)}>
          <EditIcon
            style={{
              fontSize: "25px",
              color: APP_CONFIG.mainCollors.primary,
            }}
          />
        </Box>

        <Box
          style={{ marginLeft: "20px" }}
          onClick={() => {
            setShowDeletarCartaoModal(true);
          }}
        >
          <DeleteForeverIcon
            style={{
              fontSize: "25px",
              color: "#ED757D",
            }}
          />
        </Box>
      </Box>

      <CartaoModal
        show={showEditarCartaoModal}
        setShow={setShowEditarCartaoModal}
        getData={getData}
        data={row}
        update
      />
      <DeletarCartaoModal
        show={showDeletarCartaoModal}
        setShow={setShowDeletarCartaoModal}
        getData={getData}
        data={row}
      />
    </Box>
  );
};

export default function ListaCartoes() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const token = useAuth();
  const [loading, setLoading] = useState(false);
  const [showCadastrarLoteModal, setShowCadastrarLoteModal] = useState(false);
  const [showCadastrarCartaoModal, setShowCadastrarCartaoModal] =
    useState(false);
  const headerLike = useSelector((state) => state.headerLike);
  const usuario = useSelector((state) => state.userData);
  const [listaCartoes, setListaCartoes] = useState([]);
  const [page, setPage] = useState(1);

	const gerenciarPermissao = useSelector((state) => state.gerenciarPermissao);
	const [permissoes, setPermissoes] = useState([]);

	useEffect(() => {
		dispatch(loadPermissaoGerenciar(token, usuario.id));
	}, []);

	useEffect(() => {
		const { permissao } = gerenciarPermissao;
		setPermissoes(permissao.map((item) => item.tipo));
	}, [gerenciarPermissao, gerenciarPermissao.permissao.length]);


  const getData = async (page = 1, headerLike = "") => {
    setLoading(true);
    try {
      const res = await getCartoes(token, page, headerLike);
      //setListaCartoes(res.data);
      setListaCartoes([]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(loadUserData(token));
  }, [token]);

  useEffect(() => {
    getData(page, headerLike);
  }, [token, page, headerLike]);

  useEffect(() => {
    return () => {
      dispatch(setHeaderLike(""));
    };
  }, []);

  const CartaoButtons = [
    {
      text: "Cadastrar em Lote por arquivo",
      callback: () => setShowCadastrarLoteModal(true),
    },
    {
      text: "Novo cadastro",
      callback: () => setShowCadastrarCartaoModal(true),
      color: "horizontalGradient",
      icon: <AddIcon style={{ color: "white", marginRight: "10px" }} />,
    },
  ];

  return (
    <Box className={classes.root}>
      <LoadingScreen isLoading={loading} />

      <Box className={classes.main}>
        <CustomHeader
          pageTitle="Cartões"
          isSearchVisible
          customButtons={CartaoButtons}
        />

        <Box className={classes.dadosBox}>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                backgroundColor: APP_CONFIG.mainCollors.backgrounds,
                alignItems: "center",
                borderRadius: "17px",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  borderRadius: 27,
                  borderTopLeftRadius: 27,
                  borderTopRightRadius: 27,
                }}
              >
                <Box
                  display="flex"
                  style={{
                    marginTop: "10px",
                    marginBottom: "16px",
                    margin: 30,
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      borderTopRightRadius: 27,
                      borderTopLeftRadius: 27,
                    }}
                  >
                    {listaCartoes.data && listaCartoes.per_page ? (
                      <>
                        <Box minWidth={!matches ? "800px" : null}>
                          <CustomTable
                            columns={columns ? columns : null}
                            data={listaCartoes.data}
                            Editar={(props) => (
                              <MenuTable getData={getData} {...props} />
                            )}
                          />
                        </Box>

                        <Box alignSelf="flex-end" marginTop="8px">
                          <Pagination
                            variant="outlined"
                            color="secondary"
                            size="large"
                            count={listaCartoes.last_page}
                            onChange={(e, v) => setPage(v)}
                            page={page}
                          />
                        </Box>
                      </>
                    ) : (
                      <Box>
                        <LinearProgress color="primary" />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <CadastrarLoteModal
        show={showCadastrarLoteModal}
        setShow={setShowCadastrarLoteModal}
        getData={getData}
      />
      <CartaoModal
        show={showCadastrarCartaoModal}
        setShow={setShowCadastrarCartaoModal}
        getData={getData}
      />
    </Box>
  );
}
