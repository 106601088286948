import SettingsIcon from '@material-ui/icons/Settings';
import {
	Box,
	Button,
	IconButton,
	LinearProgress,
	makeStyles,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getPagamentoPixAction, setDadosBoleto } from '../../actions/actions';
import useAuth from '../../hooks/useAuth';
import SearchIcon from '@mui/icons-material/Search';
import CustomCollapseTablePix from '../CustomCollapseTablePix/CustomCollapseTablePix';
import CustomRoundedCard from '../CustomRoundedCard/CustomRoundedCard';
import CustomFilterButton from '../CustomFilterButton/CustomFilterButton';
import moment from 'moment';
import 'moment/locale/pt-br';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PixIcon from '@mui/icons-material/Pix';
import { Pagination, TableContainer } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { APP_CONFIG } from '../../constants/config';

const useStyles = makeStyles((theme) => ({}));

const ExtratoPixContainer = ({ title, changePath, ...rest }) => {
	const classes = useStyles();
	const { section } = useParams();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const pagamentoPix = useSelector((state) => state.pagamentoPix);
	const dispatch = useDispatch();
	const token = useAuth();
	const [page, setPage] = useState(1);
	const [like, setLike] = useState('');
	const [mostrar, setMostrar] = useState(10);
	const [order, setOrder] = useState('');

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	moment.locale('pt-br');

	useEffect(() => {
		dispatch(
			getPagamentoPixAction(token, page, '', '', order, mostrar, '', '')
		);
	}, [token, page]);

	const columns = [
		{
			headerText: 'Data',
			key: 'created_at',
			CustomValue: (created_at) => {
				return <>{moment.utc(created_at).format('DD MMMM')}</>;
			},
		},

		{
			headerText: '',
			key: '',
			CustomValue: (created_at) => {
				return (
					<Box
						style={{
							backgroundColor: APP_CONFIG.mainCollors.primary,
							display: 'flex',
							flexDirection: 'column',
							height: '50px',
							width: '50px',

							borderRadius: '32px',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<PixIcon style={{ color: 'white', fontSize: '30px' }} />
					</Box>
				);
			},
		},
		{
			headerText: 'Status',
			key: 'tipo_pix',
			CustomValue: (tipo_pix) => {
				return (
					<>
						<Typography
							style={{
								fontFamily: 'Montserrat-Regular',
								fontSize: '13px',
								color: APP_CONFIG.mainCollors.primary,
							}}
						>
							Pix {tipo_pix}
						</Typography>
					</>
				);
			},
		},

		{
			headerText: 'Nome',
			key: '',
			FullObject: (data) => {
				return (
					<>
						<Typography
							style={{
								fontFamily: 'Montserrat-Regular',
								fontSize: '13px',
								color: APP_CONFIG.mainCollors.primary,
							}}
						>
							{data.response?.pix_in
								? data.response?.pix_in?.PayeeName
								: data.response?.pix_out
								? data.response?.pix_out?.payee?.name
								: data.response?.pix_refund?.PayeeName
								? data.response?.pix_refund?.PayeeName
								: null}
						</Typography>
					</>
				);
			},
		},
		{
			headerText: 'Valor',
			key: 'valor',
			CustomValue: (valor) => {
				return (
					<>
						R${' '}
						{parseFloat(valor).toLocaleString('pt-br', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</>
				);
			},
		},
		{
			headerText: '',
			key: '',
			FullObject: (data) => {
				return (
					<>
						<Button
							onClick={() => {
								dispatch(setDadosBoleto(data));

								changePath('comprovanteAprovacao');
							}}
							variant="outlined"
							color="primary"
							style={{
								fontFamily: 'Montserrat-Regular',
								fontSize: '10px',
								color: APP_CONFIG.mainCollors.primary,
								borderRadius: 20,
							}}
						>
							Visualizar
						</Button>
					</>
				);
			},
		},
	];

	const itemColumns = [
		{
			headerText: 'Data',
			key: 'banco_pagou',
			CustomValue: (banco) => {
				return (
					<>
						<Typography
							style={{
								fontFamily: 'Montserrat-Regular',
								fontSize: '15px',
								color: APP_CONFIG.mainCollors.primary,
							}}
						>
							Banco: {banco ? banco : '40473435 - REPASSES FINANCEIROS'}
						</Typography>
					</>
				);
			},
		},
		// {
		// 	headerText: 'Nome',
		// 	key: '',
		// 	FullObject: (data) => {
		// 		return (
		// 			<>
		// 				<Typography
		// 					style={{
		// 						fontFamily: 'Montserrat-Regular',
		// 						fontSize: '13px',
		// 						color: APP_CONFIG.mainCollors.primary,
		// 					}}
		// 				>
		// 					{data.response?.pix_in
		// 						? data.response?.pix_in?.PayeeName
		// 						: data.response?.pix_out
		// 						? data.response?.pix_out?.payee?.name
		// 						: data.response?.pix_refund?.PayeeName
		// 						? data.response?.pix_refund?.PayeeName
		// 						: null}
		// 				</Typography>
		// 			</>
		// 		);
		// 	},
		// },
		{
			headerText: 'Data',
			key: 'chave_recebedor',
			CustomValue: (chave) => {
				return (
					<>
						<Typography
							style={{
								fontFamily: 'Montserrat-Regular',
								fontSize: '15px',
								color: APP_CONFIG.mainCollors.primary,
							}}
						>
							Chave: {chave}
						</Typography>
					</>
				);
			},
		},
	];
	const Editar = (row) => {
		return <CustomRoundedCard icon="pix" />;
	};

	return (
		<>
			<Typography
				style={{
					fontFamily: 'Montserrat-ExtraBold',
					fontSize: '16px',
					color: APP_CONFIG.mainCollors.primary,
					marginTop: '30px',
					marginLeft: '40px',
				}}
			>
				Extrato pix
			</Typography>
			<Box
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					marginTop: '10px',
				}}
			>
				<Box
					style={{
						width: '90%',
						height: '1px',
						backgroundColor: APP_CONFIG.mainCollors.primary,
					}}
				/>

				<Box
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '10px',
						alignItems: 'center',
					}}
				>
					<TextField
						variant="outlined"
						label=""
						InputProps={{
							endAdornment: (
								<SearchIcon
									style={{
										fontSize: '25px',
										color: APP_CONFIG.mainCollors.primary,
									}}
								/>
							),
						}}
					/>
				</Box>
				<Box
					style={{
						marginTop: '30px',
						marginBottom: '30px',
						width: '100%',
						maxWidth: 900,
						padding: '10px',
					}}
				>
					{pagamentoPix.data && pagamentoPix.data.length > 0 ? (
						<>
							<Box minWidth={!matches ? '500px' : null}>
								<TableContainer
									style={{ overflowX: 'auto', overflowY: 'hidden' }}
								>
									<CustomCollapseTablePix
										itemColumns={itemColumns}
										data={pagamentoPix.data}
										columns={columns}
										Editar={Editar}
									/>
								</TableContainer>
							</Box>
							<Box
								alignSelf="flex-end"
								marginTop="8px"
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Pagination
									variant="outlined"
									color="secondary"
									size="large"
									count={pagamentoPix.last_page}
									onChange={handleChangePage}
									page={page}
								/>
								<Button
									style={{
										minWidth: '5px',
										height: '40px',
										borderRadius: '27px',
										border: 'solid',
										borderWidth: '1px',
										borderColor: 'grey',
									}}
									onClick={() => window.location.reload()}
								>
									<RefreshIcon
										style={{ fontSize: 25, color: 'grey' }}
									/>
								</Button>
							</Box>
						</>
					) : (
						<LinearProgress />
					)}
				</Box>
			</Box>
		</>
	);
};

export default ExtratoPixContainer;
