import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
	Box,
	Button,
	FormControlLabel,
	IconButton,
	makeStyles,
	Modal,
	Switch,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomTextField from '../CustomTextField/CustomTextField';
import useAuth from '../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { getConsultaChavePixAction } from '../../actions/actions';
import CustomButton from '../CustomButton/CustomButton';
import ReactCodeInput from 'react-code-input';
import tokenImage from '../../assets/vBankPJAssets/tokenImage.svg';
import useDebounce from '../../hooks/useDebounce';
import {
	getConsultarCodigoDeBarras,
	postPagarBoleto,
	postTransferenciaP2P,
} from '../../services/services';
import InputMask from 'react-input-mask';
import moment from 'moment';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import vBankSmallLogo from '../../assets/vBankPJAssets/vBankSmallLogo.svg';
import { APP_CONFIG } from '../../constants/config';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';

const useStyles = makeStyles((theme) => ({
	modal: {
		outline: ' none',
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'center',
		position: 'absolute',

		top: '10%',
		left: '25%',
		/* transform: 'translate(-50%, -50%)', */
		width: '50%',
		height: '80%',
		backgroundColor: 'white',
		/* bgcolor: 'background.paper', */
		border: '0px solid #000',
		boxShadow: 24,
		/* p: 5, */
	},
	boxTitle: {
		fontFamily: 'Montserrat-ExtraBold',
		fontSize: '16px',
		color: APP_CONFIG.mainCollors.primary,
		marginTop: '30px',
	},
	line: {
		width: '90%',
		height: '1px',
		backgroundColor: APP_CONFIG.mainCollors.primary,
	},
	lineGrey: {
		width: '100%',
		height: '1px',
		backgroundColor: 'grey',
		marginTop: '10px',
	},
	title: {
		fontFamily: 'Montserrat-ExtraBold',
		fontSize: '20px',
		color: APP_CONFIG.mainCollors.primary,
		fontWeight: 'bold',
	},
	text: {
		fontFamily: 'Montserrat-Regular',
		fontSize: '16px',
		color: APP_CONFIG.mainCollors.primary,
		fontWeight: 'normal',
	},
	copyIcon: {
		color: APP_CONFIG.mainCollors.primary,
		fontSize: '30px',
	},
	dataContainer: { display: 'flex', marginTop: 20, width: '100%' },
}));

const ComprovanteAprovacaoPix = ({ title, changePath, ...rest }) => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const token = useAuth();
	const [loading, setLoading] = useState(false);
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const dadosBoleto = useSelector((state) => state.dadosBoletoGerado);
	const componentRef = useRef();

	function copyToClipBoard(text) {
		navigator.clipboard.writeText(text);
		toast.success('Copiado!');
	}

	return (
		<>
			<LoadingScreen isLoading={loading} />
			<Box
				style={{
					display: 'flex',
					alignItems: 'baseline',
					alignSelf: 'center',
					width: '90%',
					justifyContent: 'space-between',
				}}
			>
				<Typography className={classes.boxTitle}>
					Dados do comprovante
				</Typography>

				<ReactToPrint
					trigger={() => {
						return (
							<Button>
								<PrintIcon
									style={{
										color: APP_CONFIG.mainCollors.primary,
									}}
								/>
							</Button>
						);
					}}
					content={() => componentRef.current}
				/>
			</Box>
			<Box
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					marginTop: '10px',
				}}
			>
				<Box className={classes.line} />
			</Box>
			<Box
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignSelf: 'center',
					padding: '15px',
				}}
				ref={componentRef}
			>
				{dadosBoleto &&
				dadosBoleto.tipo_pix === 'Pagamento' &&
				dadosBoleto.conta &&
				dadosBoleto.response?.pix_out?.payee?.account ? (
					<Box style={{ marginTop: '30px' }}>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Box>
								<img src={APP_CONFIG.assets.smallColoredLogo}></img>
							</Box>
						</Box>
						<Box style={{ marginTop: '20px' }}>
							<Typography
								style={{
									color: APP_CONFIG.mainCollors.primary,
									fontSize: '20px',
								}}
							>
								{dadosBoleto.status_aprovado === 'Aprovado'
									? 'Comprovante de transferência'
									: 'Aguardando Aprovação'}
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{moment
									.utc(dadosBoleto.created_at)
									.format('DD/MM/YYYY')}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '20px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Valor
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								R${' '}
								{parseFloat(dadosBoleto.valor).toLocaleString('pt-br', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Tipo de transferência
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.tipo_pix}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Descrição
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.descricao}
							</Typography>
						</Box>

						<Box className={classes.lineGrey} />
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
									marginTop: '20px',
									marginBottom: '10px',
								}}
							>
								Destino
							</Typography>
						</Box>
						<Box className={classes.lineGrey} />
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '20px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Nome
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.response.pix_out.payee.name}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Documento
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.response.pix_out.payee.document.length <
									12 ||
								dadosBoleto.response.pix_out.payee.document.includes(
									'-'
								) ? (
									<>
										***
										{dadosBoleto.response.pix_out.payee.document.substring(
											3,
											6
										)}
										{dadosBoleto.response.pix_out.payee.document.substring(
											6,
											11
										)}
										-**
									</>
								) : (
									dadosBoleto.response.pix_out.payee.document.substring(
										0,
										2
									) +
									'.' +
									dadosBoleto.response.pix_out.payee.document.substring(
										2,
										5
									) +
									'.' +
									dadosBoleto.response.pix_out.payee.document.substring(
										5,
										8
									) +
									'/' +
									dadosBoleto.response.pix_out.payee.document.substring(
										8,
										12
									) +
									'-' +
									dadosBoleto.response.pix_out.payee.document.substring(
										12,
										14
									)
								)}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								ISPB
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.response.pix_out.payee.account.ispb}
							</Typography>
						</Box>
						<Box className={classes.lineGrey} />
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
									marginTop: '20px',
									marginBottom: '10px',
								}}
							>
								Origem
							</Typography>
						</Box>
						<Box className={classes.lineGrey} />
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '20px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								{dadosBoleto.conta.tipo === 'Pessoa Jurídica'
									? 'Razão Social'
									: 'Nome'}
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.conta.tipo === 'Pessoa Jurídica'
									? dadosBoleto.conta.razao_social
									: dadosBoleto.conta.nome}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Documento
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.conta.documento.length < 12 ||
								dadosBoleto.conta.documento.includes('-') ? (
									<>
										***
										{dadosBoleto.conta.documento.substring(3, 6)}
										{dadosBoleto.conta.documento.substring(6, 11)}
										-**
									</>
								) : (
									dadosBoleto.conta.documento
								)}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
								marginBottom: '40px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								ISPB
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.response.pix_out.payer.account.ispb}
							</Typography>
						</Box>
					</Box>
				) : dadosBoleto &&
				  dadosBoleto.tipo_pix === 'Recebimento' &&
				  dadosBoleto.conta &&
				  dadosBoleto.response &&
				  dadosBoleto.response.pix_in ? (
					<Box style={{ marginTop: '30px' }}>
						<Box>
							<img src={APP_CONFIG.assets.smallColoredLogo}></img>
						</Box>
						<Box style={{ marginTop: '20px' }}>
							<Typography
								style={{
									color: APP_CONFIG.mainCollors.primary,
									fontSize: '20px',
								}}
							>
								{dadosBoleto.status_aprovado === 'Aprovado'
									? 'Comprovante de transferência'
									: 'Aguardando Aprovação'}
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{moment
									.utc(dadosBoleto.created_at)
									.format('DD/MM/YYYY')}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '20px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Valor
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								R${' '}
								{parseFloat(dadosBoleto.valor).toLocaleString('pt-br', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Tipo de transferência
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.tipo_pix}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Descrição
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.descricao}
							</Typography>
						</Box>

						<Box className={classes.lineGrey} />
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
									marginTop: '20px',
									marginBottom: '10px',
								}}
							>
								Destino
							</Typography>
						</Box>
						<Box className={classes.lineGrey} />
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '20px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								{dadosBoleto.conta.tipo === 'Pessoa Jurídica'
									? 'Razão Social'
									: 'Nome'}
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.conta.tipo === 'Pessoa Jurídica'
									? dadosBoleto.conta.razao_social
									: dadosBoleto.conta.nome}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Documento
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.conta.documento.length < 12 ||
								dadosBoleto.conta.documento.includes('-') ? (
									<>
										***
										{dadosBoleto.conta.documento.substring(3, 6)}
										{dadosBoleto.conta.documento.substring(6, 11)}
										-**
									</>
								) : (
									dadosBoleto.conta.documento
								)}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								ISPB
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.response.pix_in.PayerBankIspb}
							</Typography>
						</Box>
						<Box className={classes.lineGrey} />
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
									marginTop: '20px',
									marginBottom: '10px',
								}}
							>
								Origem
							</Typography>
						</Box>
						<Box className={classes.lineGrey} />
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '20px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Nome
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.response.pix_in.PayeeName}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Documento
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.response.pix_in.PayeeDocument.length <
									12 ||
								dadosBoleto.response.pix_in.PayeeDocument.includes(
									'-'
								) ? (
									<>
										***
										{dadosBoleto.response.pix_in.PayeeDocument.substring(
											3,
											6
										)}
										{dadosBoleto.response.pix_in.PayeeDocument.substring(
											6,
											11
										)}
										-**
									</>
								) : (
									dadosBoleto.response.pix_in.PayeeDocument.substring(
										0,
										2
									) +
									'.' +
									dadosBoleto.response.pix_in.PayeeDocument.substring(
										2,
										5
									) +
									'.' +
									dadosBoleto.response.pix_in.PayeeDocument.substring(
										5,
										8
									) +
									'/' +
									dadosBoleto.response.pix_in.PayeeDocument.substring(
										8,
										12
									) +
									'-' +
									dadosBoleto.response.pix_in.PayeeDocument.substring(
										12,
										14
									)
								)}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
								marginBottom: '40px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								ISPB
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.response.pix_in.PayeeBankIspb}
							</Typography>
						</Box>
					</Box>
				) : dadosBoleto?.tipo_pix === 'Devolução' &&
				  dadosBoleto?.response?.pix_refund ? (
					<Box style={{ marginTop: '30px' }}>
						<Box>
							<img src={APP_CONFIG.assets.smallColoredLogo}></img>
						</Box>
						<Box style={{ marginTop: '20px' }}>
							<Typography
								style={{
									color: APP_CONFIG.mainCollors.primary,
									fontSize: '20px',
								}}
							>
								{dadosBoleto.status_aprovado === 'Aprovado'
									? 'Comprovante de transferência'
									: 'Aguardando Aprovação'}
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{moment
									.utc(dadosBoleto.created_at)
									.format('DD/MM/YYYY')}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '20px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Valor
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								R${' '}
								{parseFloat(dadosBoleto.valor).toLocaleString('pt-br', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Tipo de transferência
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.tipo_pix}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Descrição
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.descricao}
							</Typography>
						</Box>

						<Box className={classes.lineGrey} />
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
									marginTop: '20px',
									marginBottom: '10px',
								}}
							>
								Destino
							</Typography>
						</Box>
						<Box className={classes.lineGrey} />
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '20px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Nome
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.response.pix_refund.PayeeName}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Documento
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.response.pix_refund.PayeeDocument.length <
									12 ||
								dadosBoleto.response.pix_refund.PayeeDocument.includes(
									'-'
								) ? (
									<>
										***
										{dadosBoleto.response.pix_refund.PayeeDocument.substring(
											3,
											6
										)}
										{dadosBoleto.response.pix_refund.PayeeDocument.substring(
											6,
											11
										)}
										-**
									</>
								) : (
									dadosBoleto.response.pix_refund.PayeeDocument.substring(
										0,
										2
									) +
									'.' +
									dadosBoleto.response.pix_refund.PayeeDocument.substring(
										2,
										5
									) +
									'.' +
									dadosBoleto.response.pix_refund.PayeeDocument.substring(
										5,
										8
									) +
									'/' +
									dadosBoleto.response.pix_refund.PayeeDocument.substring(
										8,
										12
									) +
									'-' +
									dadosBoleto.response.pix_refund.PayeeDocument.substring(
										12,
										14
									)
								)}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								ISPB
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.response.pix_refund.PayeeBankIspb}
							</Typography>
						</Box>
						<Box className={classes.lineGrey} />
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
									marginTop: '20px',
									marginBottom: '10px',
								}}
							>
								Origem
							</Typography>
						</Box>
						<Box className={classes.lineGrey} />
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '20px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>								
								{dadosBoleto.response.pix_refund.PayerDocumentType ===
								'Cnpj'
									? 'Razão Social'
									: 'Nome'}
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.response.pix_refund.PayerName}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								Documento
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.response.pix_refund.PayerDocument.length <
									12 ||
								dadosBoleto.response.pix_refund.PayerDocument.includes(
									'-'
								) ? (
									<>
										***
										{dadosBoleto.response.pix_refund.PayerDocument.substring(
											3,
											6
										)}
										{dadosBoleto.response.pix_refund.PayerDocument.substring(
											6,
											11
										)}
										-**
									</>
								) : (
									dadosBoleto.response.pix_refund.PayerDocument.substring(
										0,
										2
									) +
									'.' +
									dadosBoleto.response.pix_refund.PayerDocument.substring(
										2,
										5
									) +
									'.' +
									dadosBoleto.response.pix_refund.PayerDocument.substring(
										5,
										8
									) +
									'/' +
									dadosBoleto.response.pix_refund.PayerDocument.substring(
										8,
										12
									) +
									'-' +
									dadosBoleto.response.pix_refund.PayerDocument.substring(
										12,
										14
									)
								)}
							</Typography>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '10px',
								marginBottom: '40px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Montserrat-ExtraBold',
									color: APP_CONFIG.mainCollors.primary,
								}}
							>
								ISPB
							</Typography>
							<Typography
								style={{ color: APP_CONFIG.mainCollors.primary }}
							>
								{dadosBoleto.response.pix_refund.PayerBankIspb}
							</Typography>
						</Box>
					</Box>
				) : null}
			</Box>
		</>
	);
};

export default ComprovanteAprovacaoPix;
