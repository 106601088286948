import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/concorrencia/beneficiario`;

export const getBeneficiarios = async (token, page, like) => {
  const url = `${API_URL}?mostrar=10&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteBeneficiario = async (token, userID) => {
  const url = `${API_URL}/${userID}`;

  return axios({
    method: "delete",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const putUpdateBeneficiario = async (token, userID, data) => {
  // data: {
  //     "beneficiario": {
  //         "email": "",
  //         "documento": "",
  //         "nome": "",
  //         "celular": ""
  //     },
  //     "endereco": {
  //         "cep": "",
  //         "rua": "",
  //         "bairro": "",
  //         "numero": "",
  //         "complemento": "",
  //         "cidade": "",
  //         "estado": ""
  //     }
  // }
  const url = `${API_URL}/${userID}`;

  return axios({
    method: "put",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const postAddBeneficiario = async (token, data) => {
  // data: {
  //     "beneficiario": {
  //         "nome": "",
  //         "email": "",
  //         "documento": "",
  //         "celular": ""
  //     },
  //     "endereco": {
  //         "cep": "",
  //         "rua": "",
  //         "bairro": "",
  //         "numero": "",
  //         "complemento": "",
  //         "cidade": "",
  //         "estado": ""
  //     }
  // }
  const url = `${API_URL}`;

  return axios({
    method: "post",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const postAddLoteBeneficiarios = async (token, file) => {
  const url = `${API_URL}/arquivo-lote`;

  const fileForm = new FormData();
  fileForm.append("file", file);

  return axios({
    method: "post",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: fileForm,
  });
};

export const getTransacoes = async (token, page, like) => {
  const url = `${process.env.REACT_APP_API_URL}/concorrencia/extrato?mostrar=10&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getTransacoesPre = async (token, cartao, page, like) => {
  const url = `${process.env.REACT_APP_API_URL}/conta/cartao-pre-pago-externo/${cartao}/history?mostrar=10&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getTransacaoBeneficiario = async (token, id, page, like) => {
  const url = `${process.env.REACT_APP_API_URL}/concorrencia/extrato?user_id=${id}&mostrar=10&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCartoes = async (token, page, like) => {
  const url = `${API_URL}/cartoes?mostrar=10&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCartoesPre = async (token, page, like) => {
  const url = `${process.env.REACT_APP_API_URL}/conta/cartao-pre-pago?mostrar=10&page=${page}&like=${like}`;

  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteCartao = async (token, cardID) => {
  const url = `${API_URL}/${cardID}/card`;

  return axios({
    method: "delete",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const putUpdateCartao = async (token, cardID, data) => {
  const url = `${API_URL}/${cardID}/card`;

  return axios({
    method: "put",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const postAddCartao = async (token, data) => {
  // data: {
  //   documento: "",
  //   cdCartao: "",
  //   externoMsk: "",
  // }
  const url = `${API_URL}/card`;

  return axios({
    method: "post",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const postAddLoteCartoes = async (token, file) => {
  const url = `${API_URL}/card/arquivo-lote`;

  const fileForm = new FormData();
  fileForm.append("file", file);

  return axios({
    method: "post",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: fileForm,
  });
};
